var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('validation-observer',{ref:"ingredientMasterValidation"},[_c('b-form',[_c('b-row',[_c('b-col',[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],attrs:{"variant":"secondary"},on:{"click":function($event){return _vm.$router.go(-1)}}},[_c('feather-icon',{attrs:{"icon":"SkipBackIcon"}}),_vm._v(" "+_vm._s(_vm.$t("Common.Back"))+" ")],1)],1)],1),_c('b-row',{staticClass:"mt-2"},[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Ingredient Master List.Ingredient Name')}},[_c('validation-provider',{attrs:{"name":"Ingredient Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"type":"text","placeholder":_vm.$t('Ingredient Master List.Ingredient Name'),"disabled":!_vm.$can('write', 'food')},model:{value:(_vm.form.mingredient_name),callback:function ($$v) {_vm.$set(_vm.form, "mingredient_name", $$v)},expression:"form.mingredient_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Ingredient Master List.Ingredient Code')}},[_c('validation-provider',{attrs:{"name":"Ingredient Code","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"type":"text","placeholder":_vm.$t('Ingredient Master List.Ingredient Code'),"disabled":!_vm.$can('write', 'food')},model:{value:(_vm.form.mingredient_code),callback:function ($$v) {_vm.$set(_vm.form, "mingredient_code", $$v)},expression:"form.mingredient_code"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Ingredient Master List.Cuisine Name')}},[_c('validation-provider',{attrs:{"name":"Cuisine","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"reduce":function (cuisine) { return cuisine.cuisine_id; },"label":"cuisine_name","placeholder":_vm.$t('Ingredient Master List.Cuisine Name'),"options":_vm.$store.getters['masters/getAllCuisine'],"disabled":!_vm.$can('write', 'food')},model:{value:(_vm.form.mingredient_cuisine_id),callback:function ($$v) {_vm.$set(_vm.form, "mingredient_cuisine_id", $$v)},expression:"form.mingredient_cuisine_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Ingredient Master List.Country Name')}},[_c('validation-provider',{attrs:{"name":"Country","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"reduce":function (country) { return country.country_id; },"label":"country_name","placeholder":_vm.$t('Ingredient Master List.Country Name'),"options":_vm.$store.getters['masters/getAllCountry'],"disabled":!_vm.$can('write', 'food')},model:{value:(_vm.form.mingredient_country_id),callback:function ($$v) {_vm.$set(_vm.form, "mingredient_country_id", $$v)},expression:"form.mingredient_country_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Ingredient Master List.Ingredient Description')}},[_c('validation-provider',{attrs:{"name":"Ingredient Description","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"state":errors.length > 0 ? false : null,"type":"text","disabled":!_vm.$can('write', 'food'),"placeholder":_vm.$t('Ingredient Master List.Ingredient Description'),"rows":"3"},model:{value:(_vm.form.mingredient_desc),callback:function ($$v) {_vm.$set(_vm.form, "mingredient_desc", $$v)},expression:"form.mingredient_desc"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"text-center",attrs:{"cols":"12"}},[_c('b-button',{attrs:{"variant":"primary","type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.saveIngredientMaster.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t("Common.Save"))+" ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }